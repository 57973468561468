import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Box } from 'src/components/atoms/Box';
import { DeveloperSection } from 'src/containers/DeveloperSection';
import { Image } from 'src/containers/Image2';
import { SEO } from 'src/containers/SEO';
import { EventTeaser } from 'src/containers/EventTeaser';
import { CommunityChapters } from 'src/containers/CommunityChapters';
import { InlineList } from 'src/components/molecules/InlineList';
import { DynamicCount } from 'src/containers/DynamicCount';
import { Link } from 'src/containers/Link';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query {
    rasaCommunityPng: file(relativePath: { eq: "rasa-community.png" }) {
      ...Image2
    }
    rasaSummit_2019Png: file(relativePath: { eq: "rasa_summit_2019.png" }) {
      ...Image2
    }
    rasaContributorsPng: file(relativePath: { eq: "rasa_contributors.png" }) {
      ...Image2
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO description="A community of makers and conversational AI enthusiasts. Join to chat and work with makers using open source NLU tools!" mdxType="SEO" />
    <DeveloperSection hideCta mdxType="DeveloperSection" />
    <Section mdxType="Section">
      <Grid mdxType="Grid">
        <div>
          <h2 {...{
            "id": "find-answers-to-your-questions-in-our-forum"
          }}>{`Find answers to your questions in our Forum`}</h2>
          <p>{`The Community Forum is the main place where our community meets to discuss all things Rasa and conversational AI. Join the `}<DynamicCount type="forumMembers" mdxType="DynamicCount" />{`+ Rasa and conversational AI enthusiasts on the forum.`}</p>
          <ul>
            <li parentName="ul">{`Connect with fellow community members`}</li>
            <li parentName="ul">{`Get answers to your technical questions`}</li>
            <li parentName="ul">{`Find and share useful resources`}</li>
            <li parentName="ul">{`Stay up-to-date with the latest Rasa events`}</li>
            <li parentName="ul">{`Find or host a meetup in your area!`}</li>
          </ul>
          <Button to="https://forum.rasa.com" mdxType="Button">Join the Community Forum</Button>
        </div>
        <Image data={props.data.rasaCommunityPng} alt="Our community" mdxType="Image" />
      </Grid>
      <Grid mdxType="Grid">
        <Column mdxType="Column">
          <h2 {...{
            "id": "contribute-to-rasa"
          }}>{`Contribute to Rasa`}</h2>
          <p>{`Rasa is an open source project which thrives from community contributions. There are lots of different ways to get involved and grow as a contributor in Rasa Community. Join the Rasa Contributor program and become a contributor today!`}</p>
          <Button to="/contribute" mdxType="Button">Become a contributor</Button>
        </Column>
        <Column mdxType="Column">
          <Image data={props.data.rasaContributorsPng} alt="Our community heroes &amp; contributors" mdxType="Image" />
        </Column>
      </Grid>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      